<template>
  <div class="user-photo-modal">
    <div class="user-photo-modal-wrapper">
      <h5 :dataslide="currentIndex">
        USER PHOTOS
        <loaderspin v-if="loading"></loaderspin>
      </h5>
      <Carousel
        ref="carouselUserPhoto"
        :infinite="false"
        :per-page="1"
        :pagination-color="'#DFDFDF'"
        :pagination-active-color="'#B32656'"
      >
        <slide v-for="(slide, key) in images" :key="key" class="slides">
          <div class="user-photo-modal-owner">
            <figure>
              <img
                v-if="review.owner.image"
                :src="review.owner.image"
                onerror="this.src='/static/img/avatar-soco.png';"
              />
              <img v-else src="/static/img/avatar-soco.png" />
            </figure>
            <div class="user-photo-modal-info">
              <a href="" class="user-photo-modal-name" @click.prevent>
                {{ review.owner.name }}
              </a>
            </div>
            <div class="user-photo-modal-current-index">
              {{ key + 1 }}/{{ review.images.length }} Photo<template v-if="review.images.length > 1">s</template>
            </div>
          </div>
          <div class="user-photo-modal-detail">
            <div class="user-photo-modal-image">
              <img
                v-if="slide.name"
                :src="slide.name"
                onerror="this.src='/static/mask-station/default-image-product.png';"
              />
              <img v-else src="/static/img/mask-station/default-image-product.png" />
            </div>
            <div v-if="'description' in slide && slide.description" class="user-photo-modal-desc">
              <p v-html="slide.description"></p>
            </div>
          </div>
        </slide>
      </Carousel>

      <a href="" class="user-photo-modal-close" @click.prevent="close()"></a>

      <template v-if="!loaded">
        {{ slideToPage() }}
      </template>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'UserPhotoModal',
  components: {
    Carousel,
    Slide,
  },
  props: ['images', 'start', 'review'],
  data() {
    return {
      right: false,
      skip: 1,
      loading: false,
      loaded: false,
    };
  },
  computed: {
    currentIndex: {
      cache: false,
      get() {
        if (this.$refs.carouselUserPhoto) {
          // eslint-disable-next-line no-unused-vars
          const endslide = this.images.length - 1;
        }

        return this.$refs.carouselUserPhoto ? this.$refs.carouselUserPhoto.currentPage : 0;
      },
    },
  },
  methods: {
    imageError(e) {
      e.target.src = '/static/img/avatar-soco.png';
    },
    close() {
      this.$emit('closeModal');
    },
    slideToPage() {
      this.loaded = true;
      setTimeout(
        function () {
          this.$refs.carouselUserPhoto.goToPage(this.start);
        }.bind(this),
        100
      );
    },
  },
};
</script>
