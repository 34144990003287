<template>
  <div v-if="product && Object.keys(product).length > 0" class="product-display">
    <div class="product-display__slide-image">
      <!-- Swiper -->
      <!-- <div v-if="$SO.size(attributesImage[groupActiveProductAttribute])">
			  <div class="swiper-container gallery-top" ref="galleryTop">
			    <div class="swiper-wrapper">
			      <div class="swiper-slide" :style="{backgroundImage: `url(${image.url})`}" v-for="(image, key) in attributesImage[groupActiveProductAttribute]"></div>
			    </div>
			    <div class="product-display--swiper-button-next swiper-button-next swiper-button-white"></div>
			    <div class="product-display--swiper-button-prev swiper-button-prev swiper-button-white"></div>
			  </div>
			  <div class="swiper-container gallery-thumbs" ref="galleryThumbs">
			    <div class="swiper-wrapper">
			      <div class="swiper-slide" :style="{backgroundImage: `url(${image.url})`}" v-for="(image, key) in attributesImage[groupActiveProductAttribute]"></div>
			    </div>
			  </div>
			</div>
			<div v-else>
				<div class="swiper-container gallery-top" ref="galleryTop">
				  <div class="swiper-wrapper">
				    <div class="swiper-slide" :style="{backgroundImage: `url(/static/mask-station/default-image-product.png)`}"></div>
				  </div>
				  <div class="product-display--swiper-button-next swiper-button-next swiper-button-white"></div>
				  <div class="product-display--swiper-button-prev swiper-button-prev swiper-button-white"></div>
				</div>
				<div class="swiper-container gallery-thumbs" ref="galleryThumbs">
				  <div class="swiper-wrapper">
				    <div class="swiper-slide" :style="{backgroundImage: `url(/static/mask-station/default-image-product.png)`}"></div>
				  </div>
				</div>
			</div> -->

      <template v-if="listImages.length">
        <template v-if="$store.state.productDetail.data.selectedImage">
          <div class="gallery-top">
            <div class="swiper-slide">
              <img
                v-if="$store.state.productDetail.data.selectedImage"
                :src="$store.state.productDetail.data.selectedImage"
                @error="imageError($event)"
              />
              <img v-else src="/static/img/mask-station/default-image-product.png" />
            </div>
          </div>
        </template>
        <template v-if="!$store.state.productDetail.data.selectedImageList.length">
          <div v-if="listImages.length" ref="galleryTop" class="swiper-container gallery-top">
            <div class="swiper-wrapper" :class="{ hidden: $store.state.productDetail.data.selectedImage }">
              <div v-for="(image, key) in listImages" :key="key" class="swiper-slide">
                <img v-if="image" :src="image" @error="imageError($event)" />
                <img v-else src="/static/img/mask-station/default-image-product.png" />
              </div>
            </div>
            <div
              class="product-display--swiper-button-next swiper-button-next swiper-button-white"
              @click.prevent="emptySelectedImage()"
            ></div>
            <div
              class="product-display--swiper-button-prev swiper-button-prev swiper-button-white"
              @click.prevent="emptySelectedImage()"
            ></div>
          </div>
          <div ref="galleryThumbs" class="swiper-container gallery-thumbs">
            <div class="swiper-wrapper">
              <div
                v-for="(image, key) in listImages"
                :key="key"
                class="swiper-slide"
                @click.prevent="emptySelectedImage()"
              >
                <img v-if="image" :src="image" @error="imageError($event)" />
                <img v-else src="/static/img/mask-station/default-image-product.png" />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            v-if="
              Object.keys($store.state.productDetail.data.selectedImageList).length &&
              !$store.state.productDetail.data.selectedImage
            "
            ref="galleryTop"
            class="swiper-container gallery-top"
          >
            <div class="swiper-wrapper" :class="{ hidden: $store.state.productDetail.data.selectedImage }">
              <div
                v-for="(image, key) in $store.state.productDetail.data.selectedImageList"
                :key="key"
                class="swiper-slide"
              >
                <img v-if="image" :src="image.url" @error="imageError($event)" />
                <img v-else src="/static/img/mask-station/default-image-product.png" />
              </div>
            </div>
            <div
              class="product-display--swiper-button-next swiper-button-next swiper-button-white"
              @click.prevent="emptySelectedImage()"
            ></div>
            <div
              class="product-display--swiper-button-prev swiper-button-prev swiper-button-white"
              @click.prevent="emptySelectedImage()"
            ></div>
          </div>
          <div ref="galleryThumbs" class="swiper-container gallery-thumbs">
            <div class="swiper-wrapper">
              <div
                v-for="(image, key) in $store.state.productDetail.data.selectedImageList"
                :key="key"
                class="swiper-slide"
                @click.prevent="emptySelectedImage()"
              >
                <img v-if="image.url" :src="image.url" @error="imageError($event)" />
                <img v-else src="/static/img/mask-station/default-image-product.png" />
              </div>
            </div>
          </div>
        </template>
      </template>

      <template v-else>
        <div ref="galleryTop" class="swiper-container gallery-top">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <template v-if="product.image_cover">
                <img
                  :src="
                    product.image_cover ? product.image_cover : '/static/img/mask-station/default-image-product.png'
                  "
                  @error="imageError($event)"
                />
              </template>
              <template v-else-if="product.images && product.images.length">
                <img
                  :src="product.images ? defaultImage(product) : '/static/img/mask-station/default-image-product.png'"
                  @error="imageError($event)"
                />
              </template>
            </div>
          </div>
          <div
            class="product-display--swiper-button-next swiper-button-next swiper-button-white"
            @click.prevent="emptySelectedImage()"
          ></div>
          <div
            class="product-display--swiper-button-prev swiper-button-prev swiper-button-white"
            @click.prevent="emptySelectedImage()"
          ></div>
        </div>
        <div ref="galleryThumbs" class="swiper-container gallery-thumbs">
          <template v-if="product.image_cover">
            <div class="swiper-wrapper">
              <div class="swiper-slide" @click.prevent="emptySelectedImage()">
                <img
                  :src="
                    product.image_cover ? product.image_cover : '/static/img/mask-station/default-image-product.png'
                  "
                  @error="imageError($event)"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="swiper-wrapper">
              <div
                v-for="(image, key) in product.images"
                :key="key"
                class="swiper-slide"
                @click.prevent="emptySelectedImage()"
              >
                <img v-if="image.url" :src="image.url" @error="imageError($event)" />
                <img v-else src="/static/img/mask-station/default-image-product.png" />
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>

    <div class="product-display__info">
      <h2 class="product-display__brand-name">
        {{ product.manufacturer_name ? product.manufacturer_name : product.manufacture.name }}
      </h2>
      <p class="product-display__name">{{ product.product_name ? product.product_name : product.name }}</p>

      <template>
        <p class="product-display__price">
          {{ productPrice(product) }}
        </p>
        <template v-if="productDiscount(product)">
          <p class="product-display__price_discount">
            {{ productDiscount(product) }}
          </p>
          <p class="product-display__price_old">
            {{ productPriceOld(product) }}
          </p>
        </template>
      </template>

      <div class="reviews__slide">
        <template v-if="!product.review_stats">
          <div class="rating--uniform timeline-review">
            <!-- <div class="rate-star--uniform" :class="['rate-star--uniform-' + Number(productSoco.counter_review_rating).toFixed(1) * 10]">
              <span></span><span></span><span></span><span></span><span></span>
            </div>
            <span class="average-rating">{{ productSoco && Object.keys(productSoco).length ? Number(productSoco.counter_review_rating).toFixed(1) : '0' }}</span>
            <span class="total-reviews">({{ productSoco.counter_review_user ? productSoco.counter_review_user : '0' }})</span> -->
            <div class="rate-star--uniform" :class="['rate-star--uniform-' + Number(product.rating).toFixed(1) * 10]">
              <span></span><span></span><span></span><span></span><span></span>
            </div>
            <span class="average-rating">{{
              product && Object.keys(product).length ? Number(product.rating).toFixed(1) : '0'
            }}</span>
            <span class="total-reviews">({{ product.total_review ? product.total_review : '0' }})</span>
          </div>
        </template>
        <template v-else>
          <div class="rating--uniform timeline-review">
            <!-- <div class="rate-star--uniform" :class="['rate-star--uniform-' + Number(productSoco.counter_review_rating).toFixed(1) * 10]">
              <span></span><span></span><span></span><span></span><span></span>
            </div>
            <span class="average-rating">{{ productSoco && Object.keys(productSoco).length ? Number(productSoco.counter_review_rating).toFixed(1) : '0' }}</span>
            <span class="total-reviews">({{ productSoco.counter_review_user ? productSoco.counter_review_user : '0' }})</span> -->
            <div
              class="rate-star--uniform"
              :class="['rate-star--uniform-' + Number(product.review_stats.average_rating).toFixed(1) * 10]"
            >
              <span></span><span></span><span></span><span></span><span></span>
            </div>
            <span class="average-rating">{{
              product && Object.keys(product).length ? Number(product.review_stats.average_rating).toFixed(1) : '0'
            }}</span>
            <span class="total-reviews"
              >({{ product.review_stats.total_reviews ? product.review_stats.total_reviews : '0' }})</span
            >
          </div>
        </template>
      </div>
      <p v-if="totalRecomended" class="product-display__info-reviewer">
        {{ totalRecomended }} reviewer recommend this. <a href="" @click.prevent="goToReview">See review</a>
      </p>

      <template v-for="(attributes, key) in attributesProduct">
        <template v-for="(objAttribute, prop, index) in attributes">
          <div
            v-if="prop != 'Non Specify'"
            :key="index"
            class="product-display__attributes"
            :class="{ choosed: $store.state.productDetail.data.couple.length }"
          >
            <div class="product-display__attributes__name">
              <span class="attribute-name">{{ prop }}</span>
            </div>

            <div class="product-display__attributes__item">
              <ul class="list-attribute">
                <!-- <li class="list-attribute__item"
									v-for="(attribute, keyData) in attributes[prop].data"
									:class="{active: attribute.isActive, couple: $store.state.productDetail.data.couple.indexOf(attribute.id) > -1, match: $store.state.productDetail.data.firstmatch == attribute.id}"
									@click.prevent="updateAttributeActive(prop, attribute.id, {keyAttributes: key, keyData: keyData})"
								>
									{{ attribute.name }}
								</li> -->
                <li
                  v-for="(attribute, keyData) in attributes[prop].data"
                  :key="attribute.id"
                  class="list-attribute__item"
                  :class="{
                    active:
                      String($store.state.productDetail.data.currentCouple).length &&
                      String($store.state.productDetail.data.currentCouple).indexOf(attribute.id) > -1 &&
                      String($store.state.productDetail.data.couple).length,
                  }"
                  @click.prevent="updateAttributeActive(prop, attribute.id, { keyAttributes: key, keyData: keyData })"
                >
                  {{ attribute.name }}
                </li>
              </ul>
            </div>
          </div>
        </template>
      </template>

      <div v-if="offlineStoreStock || sociollaStock" class="product-display__additional-info">
        <div class="bolder available">AVAILABLE AT</div>
        <p class="product-display__additional-info__new">
          <span class="arrow"><img src="/static/img/lipstickbar/arrow-available.svg" />Sociolla.com</span>
          <template v-if="offlineStoreStock">
            |
            <span class="store"><img src="/static/img/lipstickbar/store-icon.svg" />Offline Store</span>
          </template>
        </p>
      </div>

      <h5 class="product-display__heading-description">DESCRIPTION</h5>

      <p v-if="product.product_description" class="product-display__description" :class="{ 'see-more': isSeeMore }">
        <span class="product-display__description_inner" v-html="product.product_description"></span>
      </p>
      <p v-else-if="product.description" class="product-display__description" :class="{ 'see-more': isSeeMore }">
        <span class="product-display__description_inner" v-html="product.description"></span>
      </p>
      <p v-else class="product-display__description">-</p>
      <div v-if="isSeeMoreActive" class="product-display__link">
        <a v-if="isSeeMore" href="" class="text-see-more less" @click.prevent="toggleSeeMore">SEE LESS</a>
        <a v-else href="" class="text-see-more" @click.prevent="toggleSeeMore">SEE MORE</a>
      </div>
    </div>
  </div>
</template>

<script>
import ls from '~/src/modules/local-storage';

let galleryThumbs;
let galleryTop;

export default {
  name: 'ProductDisplay',
  props: {
    attributesImage: {
      type: Object,
      required: true,
    },
    attributesProduct: {
      type: Array,
      required: true,
    },
    groupActiveProductAttribute: {
      type: [Number, String],
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      productSoco: '',
      offlineStoreStock: false,
      isSeeMoreActive: false,
      isSeeMore: false,
    };
  },
  computed: {
    price() {
      if (this.selectedAttribute) {
        return this.selectedAttribute.price_after_discount > 0
          ? this.selectedAttribute.price_after_discount
          : this.selectedAttribute.price;
      } else {
        return this.product.price_after_discount > 0 ? this.product.price_after_discount : this.product.price;
      }
    },
    sociollaStock() {
      if (this.product.available_for_order) {
        return true;
      } else {
        return false;
      }
    },
    totalRecomended() {
      if (this.$SO.size(this.productSoco)) {
        return this.productSoco.review_stats.recommended;
      } else {
        return '';
      }
    },
    selectedAttribute() {
      return this.$store.state.productDetail.data.selectedAttribute;
    },
    listImages() {
      const listImages = [];
      if ('product_attribute' in this.product && this.product.product_attribute.length) {
        const attr = this.product.product_attribute;
        for (const i in attr) {
          if ('images' in attr[i] && attr[i].images && attr[i].images.length) {
            for (const j in attr[i].images) {
              listImages.push(attr[i].images[j].url);
            }
          }
        }
      }
      return listImages;
    },
  },
  watch: {
    $route(to, from) {},
  },
  mounted() {
    galleryThumbs = new Swiper(this.$refs.galleryThumbs, {
      spaceBetween: 10,
      slidesPerView: 3,
      loop: false,
      freeMode: true,
      loopedSlides: 3, //looped slides should be the same
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    });

    galleryTop = new Swiper(this.$refs.galleryTop, {
      spaceBetween: 10,
      loop: false,
      loopedSlides: 5, //looped slides should be the same
      navigation: {
        nextEl: '.product-display--swiper-button-next',
        prevEl: '.product-display--swiper-button-prev',
      },
      thumbs: {
        swiper: galleryThumbs,
      },
    });
    const thisel = this;
    setTimeout(function () {
      try {
        const descHeight = document.getElementsByClassName('product-display__description_inner')[0].offsetHeight;
        if (descHeight > 139) {
          thisel.isSeeMoreActive = true;
        }
      } catch (err) {}
    }, 400);
  },
  created() {
    this.emptySelectedImage();
    this.emptySelectedImageList();
    this.emptySelectedAttribute();
    this.emptyCurrentCouple();
    this.emptyTotalReview();
    if (this.product.id_product_soco) {
      this.$store
        .dispatch('getRecomendedReview', this.product.id_product_soco)
        .then((response) => {
          if (response.status === 200) {
            this.productSoco = response.data.data;
            this.$store.commit('setTotalReview', this.productSoco.review_stats.count);
          } else {
            this.productSoco = [];
          }
        })
        .catch((err) => {
          this.productSoco = [];
        });
    } else {
      this.$store
        .dispatch('getRecomendedReview', this.product.id)
        .then((response) => {
          if (response.status === 200) {
            this.productSoco = response.data.data;
            this.$store.commit('setTotalReview', this.productSoco.review_stats.count);
          } else {
            this.productSoco = [];
          }
        })
        .catch((err) => {
          this.productSoco = [];
        });
    }
    this.offlineStoreStock = this.product.offline_stock;
    ls.set(
      'brand-image',
      'manufacturer_image' in this.product && this.product.manufacturer_image ? this.product.manufacturer_image : ''
    );
  },
  beforeDestroy() {
    this.emptySelectedImage();
    this.emptySelectedImageList();
    this.emptySelectedAttribute();
    this.emptyCurrentCouple();
    this.emptyTotalReview();
  },
  methods: {
    toggleSeeMore() {
      this.isSeeMore = !this.isSeeMore;
      if (this.isSeeMoreActive === false) {
        window.scrollTo(0, 0);
      }
    },
    defaultImage(item) {
      let result;
      const images = item.images;
      result = images.find((res) => res.is_cover);
      if (result && result != undefined) {
        result = result.url;
      } else {
        result = images.length ? images[0].url : '';
      }
      return result;
    },
    updateAttributeActive(attribute_group_name, id_attribute, { keyAttributes, keyData }) {
      const thisel = this;
      this.$store.commit('updateAttributeActive', {
        attribute_group_name,
        id_attribute,
        keyAttributes,
        keyData,
        galleryThumbs,
        galleryTop,
        thisel,
      });
    },
    emptySelectedImage() {
      this.$store.commit('selectedImage', { image: '', data: this });
    },
    emptySelectedImageList() {
      this.$store.commit('selectedImageList', []);
    },
    emptySelectedPrice() {
      this.$store.commit('selectedPrice', '');
    },
    emptySelectedAttribute() {
      this.$store.commit('selectedAttribute', '');
    },
    emptyCurrentCouple() {
      this.$store.commit('currentCouple', []);
    },
    emptyTotalReview() {
      this.$store.commit('setTotalReview', '');
    },
    productPrice(product) {
      if (this.selectedAttribute) {
        product = this.selectedAttribute;
      }
      if (
        'max_price_discount' in product &&
        product.max_price_discount &&
        product.min_price_discount &&
        product.max_price_discount != product.min_price_discount
      ) {
        return `Rp ${this.$options.filters.currency(product.min_price_discount)} - Rp ${this.$options.filters.currency(
          product.max_price_discount
        )}`;
      } else if (
        'max_price' in product &&
        product.max_price &&
        product.min_price &&
        product.max_price != product.min_price
      ) {
        return `Rp ${this.$options.filters.currency(product.min_price)} - Rp ${this.$options.filters.currency(
          product.max_price
        )}`;
      } else if ('discount_range' in product) {
        if (product.discount_range) {
          return this.product.price_after_discount_range;
        } else {
          return this.product.price_range;
        }
      } else {
        return `Rp ${this.$options.filters.currency(this.price)}`;
      }
    },
    productPriceOld(product) {
      if (this.selectedAttribute) {
        product = this.selectedAttribute;
      }
      if ('min_price' in product && product.max_price && product.min_price && product.max_price != product.min_price) {
        return `Rp ${this.$options.filters.currency(product.min_price)} - Rp ${this.$options.filters.currency(
          product.max_price
        )}`;
      } else if ('discount_range' in product) {
        if (product.discount_range) {
          return product.price_range;
        } else {
          return product.price_after_discount_range;
        }
      } else {
        return `Rp ${this.$options.filters.currency(product.price)}`;
      }
    },
    productDiscount(product) {
      if (product.discount) {
        if (this.$store.state.productDetail.data.selectedAttribute) {
          product = this.$store.state.productDetail.data.selectedAttribute;
        }
        let output = '';
        output += 'SAVE ';
        if (
          'min_price' in product &&
          product.min_price > 0 &&
          product.max_price > 0 &&
          product.min_price != product.max_price
        ) {
          output += 'UP TO ';
        }
        if (product.discount_type == 'percent') {
          output += `-${product.discount}%`;
        }
        if (product.discount_type == 'amount') {
          output += `Rp ${this.$options.filters.currency(product.discount)}`;
        }
        return output;
      }
    },
    goToReview() {
      if (document.getElementsByClassName('reviews')[0] != undefined) {
        document.getElementsByClassName('reviews')[0].scrollIntoView();
      }
    },
    imageError(e) {
      e.target.src = '/static/img/mask-station/default-image-product.png';
    },
  },
};
</script>
