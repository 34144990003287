<template>
  <div>
    <div v-if="$SO.size(reviewsProduct)" ref="reviewsSlide" class="swiper-container">
      <div class="swiper-wrapper">
        <div v-for="(reviews, keyParent) in reviewsProduct" :key="keyParent" class="swiper-slide">
          <div v-for="(review, keyData) in reviews" :key="keyData" class="reviews__slide__box">
            <div class="reviews__slide__container">
              <div class="reviews__slide__user">
                <GImageUser
                  v-if="review.owner"
                  :username="review.owner.name"
                  :src="review.owner.image"
                  class-name="reviews__slide__user__img"
                />
                <div class="content">
                  <!-- <a :href="`${$store.state.SOCO_WEB_URL}/${review.owner.username}/timeline`" target="_blank" class="reviews__slide__user__fullname">{{ review.owner.firstname }} {{ review.owner.lastname }}</a> -->
                  <a
                    v-if="review.owner"
                    href=""
                    target="_blank"
                    class="reviews__slide__user__fullname"
                    @click.prevent
                    >{{ review.owner.name || review.owner.firstname + ' ' + review.owner.lastname }}</a
                  >
                  <span v-if="$SO.size(review.owner.skin_types)" class="reviews__slide__skin-heading"
                    >Jenis Kulit :</span
                  >
                  <span v-for="(skin_type, i) in review.owner.skin_types" :key="i" class="reviews__slide__skin-type">{{
                    skin_type.name
                  }}</span>
                </div>
              </div>

              <p class="reviews__slide__datetime">{{ review.created_at | dMyyyy }}</p>

              <p v-if="review.is_verified_purchase" class="text-purchased">
                <img src="/static/img/mask-station/icon_bag.svg" class="icon-bag" /> Purchased at Sociolla
              </p>

              <div class="rating--uniform timeline-review">
                <div
                  class="rate-star--uniform"
                  :class="'rate-star--uniform-' + $options.filters.rating(review.counter_star)"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <span class="average-rating">{{ Number(review.counter_star).toFixed(1) }}</span>
              </div>

              <ul class="list-give-rating">
                <li
                  v-for="(ratingType, keyRating) in review.ratingTypes"
                  :key="keyRating"
                  class="list-give-rating__item"
                >
                  {{ ratingType.name | generatedRatingTypeName }}
                  <span class="list-give-rating__value"
                    ><span class="bolder">{{ ratingType.value }}</span
                    >/5</span
                  >
                </li>
              </ul>

              <template v-if="review.details.length > 282">
                <p
                  v-if="review.isSeeMoreActive == false"
                  class="reviews__slide__content"
                  v-html="$options.filters.shortenString(review.details)"
                ></p>
                <p v-else class="reviews__slide__content see-more" v-html="review.details"></p>

                <a
                  v-if="review.isSeeMoreActive === false"
                  href=""
                  class="text-see-more"
                  @click.prevent="toggleSeeMoreReview(keyParent, keyData)"
                  >SEE MORE</a
                >
                <a v-else href="" class="text-see-more" @click.prevent="toggleSeeMoreReview(keyParent, keyData)"
                  >SEE LESS</a
                >
              </template>
              <template v-else>
                <p class="reviews__slide__content see-more" v-html="review.details"></p>
              </template>

              <p class="text-repurchased">
                Repurchased this product? <span class="bolder">{{ review.is_repurchase }}</span>
              </p>
            </div>

            <ul v-if="$SO.size(review.images)" class="list-user-photo">
              <template v-for="(image, key) in review.images">
                <li :key="key" class="list-user-photo__item">
                  <img
                    v-if="image.name"
                    :src="image.name"
                    class="list-user-photo__img"
                    onerror="this.src='/static/mask-station/default-image-product.png';"
                    @click="openModal(review.images, key, review)"
                  />
                  <img
                    v-else
                    src="/static/img/mask-station/default-image-product.png"
                    class="list-user-photo__img"
                    @click="openModal(review.images, key, review)"
                  />
                </li>
              </template>
            </ul>

            <ul v-if="$SO.size(review.review_images)" class="list-user-photo">
              <template v-for="(review_images, key) in review.review_images">
                <li :key="key" class="list-user-photo__item">
                  <img
                    v-if="review_images.url"
                    :src="review_images.url"
                    class="list-user-photo__img"
                    onerror="this.src='/static/mask-station/default-image-product.png';"
                    @click="openModalV2(review.review_images, key, review)"
                  />
                  <img
                    v-else
                    src="/static/img/mask-station/default-image-product.png"
                    class="list-user-photo__img"
                    @click="openModalV2(review.review_images, key, review)"
                  />
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
      <!-- Add Pagination -->
      <div class="reviews--swiper-pagination swiper-pagination"></div>
    </div>
    <div v-else>
      <p class="text-center">No reviews found.</p>
    </div>

    <user-photo-modal
      v-if="modal && listImages && listImages.length"
      :review="review"
      :images="listImages"
      :start="start"
      @closeModal="closeModal"
    >
    </user-photo-modal>
  </div>
</template>

<script>
import UserPhotoModal from '~/src/components/global/reviews/user-photo-modal';

// eslint-disable-next-line no-unused-vars
let reviewsSlide;

export default {
  name: 'Reviews',
  components: {
    UserPhotoModal,
  },
  props: {
    reviewsProduct: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modal: false,
      listImages: '',
      start: 0,
      review: 0,
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.reviewsProduct.length > 1) {
        reviewsSlide = new Swiper(this.$refs.reviewsSlide, {
          slidesPerView: 1,
          spaceBetween: 30,
          loop: false,
          pagination: {
            el: '.reviews--swiper-pagination',
            clickable: true,
          },
        });
      }
    }, 1000);
  },
  methods: {
    toggleSeeMoreReview(keyParent, keyData) {
      // eslint-disable-next-line vue/no-mutating-props
      this.reviewsProduct[keyParent][keyData].isSeeMoreActive =
        !this.reviewsProduct[keyParent][keyData].isSeeMoreActive;
    },
    closeModal() {
      this.modal = false;
    },
    openModal(images, key, review) {
      this.modal = true;
      this.listImages = images;
      this.start = key;
      this.review = review;
    },
    openModalV2(images, key, review) {
      for (const i in images) {
        if (i == 'url') {
          images.name = i;
        }
      }

      this.modal = true;
      this.listImages = images;
      this.start = key;

      const newReview = Object.assign('', review);

      for (const i in newReview) {
        if (i == 'user') {
          newReview.owner = review.user;
        }
      }

      newReview.images = images;
      newReview.owner.name = review.user.firstname + ' ' + review.user.lastname;

      this.review = newReview;
    },
  },
};
</script>
<style>
.product-detail .swiper-universal .swiper-pagination {
  bottom: auto;
  top: -71px;
  right: 0;
  text-align: right;
  margin: 0 auto;
  width: 100%;
}
.product-detail .swiper-universal .swiper-container {
  overflow: visible;
}
</style>
